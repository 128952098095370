<template>
  <section>
    <div class="container py-32 md:py-104">
      <div class="mx-auto">
        <h1 class="text-center text-h-2xl md:text-h-3xl font-bold pb-12 md:pb-16">
          {{ header.title }}
        </h1>
        <p class="text-h-m md:text-b-xl md:font-medium text-neutral-80 text-center pb-24 md:pb-32">
          {{ header.subtitle }}
        </p>
        <div class="flex justify-center pb-16">
          <Link href="/advisors/online" class="text-black no-underline">
            <se-button type="cta" size="xlarge" class="!px-40 hidden md:inline-flex md:visible">
              <se-icon name="search" class="mr-6" :size="20" /> {{ header.ctaText }}
            </se-button>
            <se-button type="cta" size="large" class="!px-24 inline-flex md:hidden ml-0">
              <se-icon name="search" class="mr-6" :size="16" /> {{ header.ctaText }}
            </se-button>
          </Link>
        </div>
        <p class="text-center text-b-s md:text-b-m text-neutral-80">
          {{ header.description.split('@').join(totalReviews) }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SeButton from '~/shared/ui-kit/button/Button.vue';
import SeIcon from '~/shared/ui-kit/icon/Icon.vue';
import Link from '~/components/Link.vue';
import { theme } from '../../../../../../theme';

const { header } = theme.home;

const { totalReviews } = defineProps({
  totalReviews: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeHeader',
};
</script>
